<form>
  <div id="modalEl"
    class="fixed top-0 left-0 flex m-auto justify-center align-middle h-auto z-[5000] bg-topvix-button_disabled/70 max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0">
    <div class="flex justify-center items-center max-h-full w-full max-w-2xl">
      <!-- Modal content -->
      <div class="rounded-lg bg-white shadow ">
        <!-- Modal header -->
        <div class="flex items-start justify-between rounded-t border-b p-5 ">
          <h3 class="text-xl font-semibold text-gray-900  lg:text-2xl">
            Selecione a empresa que deseja acessar
          </h3>
          <button type="button" (click)="cancel()"
            class="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900  ">
            <span class="icon-[mdi--close] text-2xl"></span>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="space-y-6 p-6">

          <tip message="Como você possui acesso a mais de uma empresa, então selecione a que deseja acessar."></tip>

          <ul class="w-full">
            <li *ngFor="let item of accountList">
              <button (click)="selection(item)"
                class="flex gap-2 justify-start items-center w-full px-3 py-1 my-2 shadow-sm border rounded-lg hover:text-topvix-bg hover:bg-topvix-button duration-500 hover:duration-75 hover:transition-all">

                <img [src]="item.imagem||currentLogo" class="w-10 h-10 object-scale-down">
                <div class="flex-grow text-lg font-medium">
                  {{item.nome_fantasia}}
                </div>

              </button>
            </li>
          </ul>
        </div>
        <!-- Modal footer -->
        <div
          class="flex items-center justify-end space-x-2 rtl:space-x-reverse rounded-b border-t border-gray-200 p-6 ">
          <!-- <button type="button" routerLink="/"
          class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300   ">
          Login
        </button> -->
          <button-template (onClick)="cancel()" label="Cancelar"
          buttonStyle="outline"></button-template>
          <button-template (onClick)="confirm()" [disabled]="formAccountSelection.invalid" label="Confirmar"
            buttonStyle="filled"></button-template>
          <!-- <button type="button"
          class="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300     ">
          Login
        </button> -->
        </div>
      </div>
    </div>
  </div>
</form>
